var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mediaItem)?_c('div',{staticClass:"company-media-block-item"},[_c('div',{staticClass:"media-item-header",on:{"mousedown":_vm.onHeaderMouseDown}},[_c('button',{staticClass:"control control-button button-close",on:{"click":_vm.onCloseClick}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"}})])])]),(_vm.mediaItem)?_c('div',{staticClass:"media-item"},[_c('div',{class:{
        'company-media-block-item-embed': _vm.mediaItem.type === 'embed',
        'company-media-block-item-video': _vm.mediaItem.type === 'video' || _vm.mediaItem.type === 'broadcast',
        'company-media-block-item-video-paused': _vm.mediaItem.type === 'video' && _vm.mediaItem.isPaused,
        'company-media-block-item-image': _vm.mediaItem.type === 'image',
        'company-media-block-item-video-broadcast': _vm.mediaItem.type === 'broadcast'
      },style:({
        'background-image' : _vm.mediaItem.type === 'image' ? 'url(' + _vm.mediaItem.src + ')' : 'none',
      }),attrs:{"id":'media-item-container-' + _vm.mediaItem.id}},[(_vm.mediaItem.type === 'broadcast')?[_c('div',{staticClass:"preview-blurred"}),_c('div',{staticClass:"preview-dimmer"}),_c('div',{staticClass:"preview-contain cur-p"},[_c('stream-player',{attrs:{"src":_vm.mediaItem.src}})],1)]:_vm._e()],2)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }