


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Subject } from 'rxjs';
import { TMeetingRoomState } from '@/_modules/meeting-rooms/types/meeting-room-state.type';
import { TUser } from '@/_types/user.type';
import { TContact } from '@/_types/contact.type';
import MeetingRoom from '@/_modules/meeting-rooms/components/meeting-room/meeting-room.vue';
import BroadcastRoom from '@/_modules/meeting-rooms/components/broadcast-room/broadcast-room.vue';
import EventChat from '@/_modules/event-chat/components/event-chat/event-chat.vue';
import StreamPlayer from '@/_components/stream-player/stream-player.vue';
import FixedDraggable from '@/_components/fixed-draggable/fixed-draggable.vue';
import MeetingsHelper from '@/_helpers/meetings.helper';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TMediaItem } from '@/_modules/events/types/media-item.type';
import LiveMediaBlock from '@/_modules/promo/components/live-media-block/live-media-block.vue';
import { TLivePage } from '@/_types/promo-page/live-page.type';
import { NavigationGuardNext, Route } from 'vue-router';
import broadcastsService from '@/_services/broadcasts.service';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import PromoBroadcastSettings, { PromoBroadcastSettingsProgram } from '@/_modules/promo/components/promo-broadcast-settings/promo-broadcast-settings.vue';
import PromoBroadcastTimeCheck
  from '@/_modules/promo/components/promo-broadcast-time-check/promo-broadcast-time-check.vue';
import { TEvent } from '@/_types/event.type';
import { TChatConfig } from '@/_modules/chat/types/chat-config.type';
import chatService from '@/_modules/chat/services/chat.service';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import EmbedCodeForm from '@/_modules/events/components/embed-code-form/embed-code-form.vue';
import MediaBlockItem from '@/_modules/events/components/media-block-item/media-block-item.vue';

// See https://class-component.vuejs.org/guide/additional-hooks.html
Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  name: 'event',
  components: {
    MeetingRoom,
    BroadcastRoom,
    EventChat,
    StreamPlayer,
    FixedDraggable,
    MediaBlockItem,
    LiveMediaBlock,
    SimplePopup,
    PromoBroadcastSettings,
    PromoBroadcastTimeCheck,
    EmbedCodeForm,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      isAuthenticated: 'authStore/isAuthenticated',
      user: '_userStore/user',
      contact: 'promoPageStore/contact',
      broadcasts: 'meetingRoomsStore/broadcasts',
      meetings: 'meetingRoomsStore/meetings',
      unpinnedMediaItems: 'promoStore/unpinnedMediaItems',
      programUnpinnedMediaItems: 'promoProgramStore/unpinnedMediaItems',
      obsSettingsDialogConfig: '_eventStore/obsSettingsDialogConfig',
      zoomSettingsDialogConfig: '_eventStore/zoomSettingsDialogConfig',
      embedCodeDialogConfig: '_eventStore/embedCodeDialogConfig',
      isBroadcastTimeCheckDialogVisible: '_eventStore/isBroadcastTimeCheckDialogVisible',
    }),
    ...mapGetters('cabinetLobbyStore', {
      livePageData: 'livePageData',
      isIntroMediaUnpinned: 'isIntroMediaUnpinned',
      isAgendaMediaUnpinned: 'isAgendaMediaUnpinned',
    }),
  },
})
export default class Event extends Vue {

  public readonly PromoBroadcastSettingsProgram: typeof PromoBroadcastSettingsProgram = PromoBroadcastSettingsProgram;
  public readonly BroadcastType: typeof BroadcastType = BroadcastType;

  public readonly isAuthenticated: boolean;
  public readonly user: TUser;
  public readonly contact: TContact;
  public readonly event: TEvent;
  public readonly meetings: TMeetingRoomState[];
  public readonly broadcasts: TMeetingRoomState[];
  public readonly unpinnedMediaItems: TMediaItem[];
  public readonly programUnpinnedMediaItems: TMediaItem[];
  public readonly livePageData: TLivePage;
  public readonly isIntroMediaUnpinned: boolean;
  public readonly isAgendaMediaUnpinned: boolean;
  public readonly obsSettingsDialogConfig: TMeetingRoomConfig;
  public readonly zoomSettingsDialogConfig: TMeetingRoomConfig;
  public readonly embedCodeDialogConfig: TMeetingRoomConfig;
  public readonly isBroadcastTimeCheckDialogVisible: boolean;

  private destroyed$: Subject<void> = new Subject<void>();
  private meetingInviteCheck$: Subject<void> = new Subject<void>();

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get meetingInviteKey(): string {
    return this.$route.params.inviteKey;
  }

  public created(): void {
    this.meetingInviteCheck$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(1000),
    ).subscribe(() => {
      this.meetingInviteCheck();
    });
  }

  public beforeDestroy(): void {
    this.meetingInviteCheck$.complete();
    broadcastsService.disableActiveBroadcastsCheck();
    chatService.configure(null);
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onOBSSettingsDialogClose(): void {
    this.$store.dispatch('_eventStore/setObsSettingsDialogConfig', null);
  }

  public onZoomSettingsDialogClose(): void {
    this.$store.dispatch('_eventStore/setZoomSettingsDialogConfig', null);
  }

  public onEmbedCodeDialogClose(): void {
    this.$store.dispatch('_eventStore/setEmbedCodeDialogConfig', null);
  }

  public onBroadcastTimeCheckDialogClose(): void {
    this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', false);
  }

  public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (to.params && to.params.forceAuthPageRoute) {
      next();
    } else if (to.name === 'auth-page' && !this.isAuthenticated) {
      /* show log-in popup instead: */
      this.$store.dispatch('authStore/setAuthPopupVisible', {
        isAuthPopupVisible: true,
        targetRoute: to.params && to.params.redirect ? to.params.redirect : null,
      });
    } else {
      next();
    }
  }

  private get chatConfig(): TChatConfig {
    if (!this.isAuthenticated || !this.contact || !this.event) {
      return null;
    }
    return {
      event: this.event,
      contact: this.contact,
    };
  }

  @Watch('chatConfig', { immediate: true })
  private onChatConfigChange(): void {
    chatService.configure(this.chatConfig);
  }

  @Watch('eventId', { immediate: true })
  private onEventIdChange(): void {
    this.meetingInviteCheck$.next();
    this.$store.dispatch('promoStore/clearPromoCompanies');
    const currentEventId = this.eventId;
    if (currentEventId) {
      broadcastsService.enableActiveBroadcastsCheck(currentEventId);
    } else {
      broadcastsService.disableActiveBroadcastsCheck();
    }
    this.updatePromoProgramStore();
  }

  @Watch('meetingInviteKey', { immediate: true })
  private onMeetingInviteKeyChange(): void {
    this.meetingInviteCheck$.next();
  }

  @Watch('user', { immediate: true })
  private onUserChange(): void {
    this.meetingInviteCheck$.next();
    this.updatePromoProgramStore();
  }

  @Watch('contact', { immediate: true })
  private onContactChange(): void {
    this.meetingInviteCheck$.next();
  }

  // @Watch('unpinnedMediaItems', { immediate: true, deep: true })
  // private onUnpinnedMediaItemsChange(): void {
  //   console.log('onUnpinnedMediaItemsChange', this.unpinnedMediaItems);
  // }

  // @Watch('programUnpinnedMediaItems', { immediate: true, deep: true })
  // private onProgramUnpinnedMediaItemsChange(): void {
  //   console.log('onProgramUnpinnedMediaItemsChange', this.programUnpinnedMediaItems);
  // }

  private meetingInviteCheck(): void {
    if (
      this.$route.name !== 'meeting-invite'
      || !this.user
      || !this.contact
      || !this.meetingInviteKey
    ) {
      return;
    }

    const meetingInvite = MeetingsHelper.parseMeetingInviteKey(this.$route.params.inviteKey);
    if (
      !meetingInvite
      || meetingInvite.eventId !== this.eventId
      || !meetingInvite.meetingId
    ) {
      // TODO: show invalid invitation key info popup
      return;
    }

    const meetingRoomConfig: TMeetingRoomConfig = {
      type: MeetingRoomType.MEETING,
      eventId: this.eventId,
      meetingId: meetingInvite.meetingId,
      contactId: this.contact.id,
      meetingDate: meetingInvite.meetingDate,
    };

    this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
  }

  private updatePromoProgramStore(): void {
    if (this.eventId && this.user) {
      this.$store.dispatch('promoProgramStore/loadProgram', this.eventId);
    } else {
      this.$store.dispatch('promoProgramStore/reset');
    }
  }

}

