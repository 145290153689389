


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import IconCloseCircleOutline from '@/_modules/icons/components/icon-close-circle-outline.vue';

@Component({
  name: 'simple-popup',
  components: {
    IconCloseCircleOutline,
  },
})
export default class SimplePopup extends Vue {

  private destroyed$: Subject<void> = new Subject<void>();

  public mounted(): void {
    this.subscribeToPageEvents();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onCloseClick(): void {
    this.$emit('close');
  }

  private subscribeToPageEvents(): void {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onDocumentKeyDown);
  }

  private onDocumentKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.$emit('close');
    }
  }
}
