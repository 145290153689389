


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { Subject } from 'rxjs';
import { IFixedDraggableChild } from '@/_types/fixed-draggable-child.interface';
import { TMediaItem } from '@/_modules/events/types/media-item.type';
import StreamPlayer from '@/_components/stream-player/stream-player.vue';

@Component({
  name: 'media-block-item',
  components: {
    StreamPlayer,
  }
})
export default class MediaBlockItem extends Vue implements IFixedDraggableChild {

  @Prop({ type: Object })
  public readonly mediaItem: TMediaItem;

  public dragZoneMouseDown$: Subject<MouseEvent> = new Subject<MouseEvent>();

  public onHeaderMouseDown(event: MouseEvent): void {
    this.dragZoneMouseDown$.next(event);
  }

  public onCloseClick(): void {
    if (this.mediaItem.entityType === 'company') {
      this.$store.dispatch('promoStore/pinMediaItem', {
        externalId: this.mediaItem.entityId,
        id: this.mediaItem.id,
      });
    } else if (this.mediaItem.entityType === 'program') {
      this.$store.dispatch('promoProgramStore/pinMediaItem', {
        programId: this.mediaItem.entityId,
        id: this.mediaItem.id,
      });
    }
  }
}
