

import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import * as TRTC from 'trtc-js-sdk';
import { TranslateResult } from 'vue-i18n';
import { Moment } from 'moment';
import { Client, LocalStream, PlayOptions } from 'trtc-js-sdk';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import MeetingRoomsHelper from '@/_modules/meeting-rooms/helpers/meeting-rooms.helper';
import TRTCHelper from '@/_modules/meeting-rooms/helpers/trtc.helper';
import { TRTCMode } from '@/_modules/meeting-rooms/types/trtc-mode.enum';
import { TMeetingRoomState } from '@/_modules/meeting-rooms/types/meeting-room-state.type';
import { TContact } from '@/_types/contact.type';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import IconFullScreen from '@/_modules/icons/components/icon-full-screen.vue';
import contactsApi from '@/_api/contacts/contacts.api';
import ContactHelper from '@/_helpers/contact.helper';
import { IFixedDraggableChild } from '@/_types/fixed-draggable-child.interface';
import { Subject } from 'rxjs';
import ItemsMenu, { TItemsMenuItem } from '@/_modules/controls/components/items-menu/items-menu.vue';
import IconMicrophone from '@/_modules/icons/components/icon-microphone.vue';
import IconCamera from '@/_modules/icons/components/icon-camera.vue';
import IconShareScreen from '@/_modules/icons/components/icon-share-screen.vue';
import IconMeetingInvite from '@/_modules/icons/components/icon-meeting-invite.vue';
import IconArrowDown from '@/_modules/icons/components/icon-arrow-down.vue';
import IconCrossedOut from '@/_modules/icons/components/icon-crossed-out.vue';
import IconClock from '@/_modules/icons/components/icon-clock.vue';
import IconSmileSad from '@/_modules/icons/components/icon-smile-sad.vue';

TRTC.Logger.setLogLevel(Number(process.env.VUE_APP_TENCENT_STREAMING_APP_LOG_LEVEL) as 0 | 1 | 2 | 3 | 4 | 5 | 6);

const SPEAKING_CHECK_INTERVAL = 1000;
const SPEAKING_AUDIO_LEVEL_THRESHOLD = 0.01;
const DEFAULT_PLAYER_CONFIG_LOCAL: PlayOptions = { objectFit: 'contain', muted: true };
const BROADCAST_DISCONNECT_TIMEOUT = 20000;

@Component({
  name: 'broadcast-room',
  props: {
    meetingRoomId: Number,
  },
  components: {
    IconFullScreen,
    ItemsMenu,
    IconMicrophone,
    IconCamera,
    IconShareScreen,
    IconMeetingInvite,
    IconArrowDown,
    IconCrossedOut,
    IconClock,
    IconSmileSad,
  },
  computed: {
    ...mapGetters({
      getMeetingRoomById: 'meetingRoomsStore/getMeetingRoomById',
    }),
  },
})
export default class BroadcastRoom extends Vue implements IFixedDraggableChild {

  public dragZoneMouseDown$: Subject<MouseEvent> = new Subject<MouseEvent>();

  public meetingRoomId: string;
  public getMeetingRoomById: (meetingRoomId: string) => TMeetingRoomState;
  public type: MeetingRoomType = MeetingRoomType.BROADCAST;
  public isLoadingLocalContact: boolean = true;

  public isSystemRequirementsOK: boolean = true;
  public isSystemCheckErrorMessageVisible: boolean = true;
  public isConnecting: boolean = true;
  public accessTime: string = '00:00:00';
  public localStream: LocalStream = null;
  public isLocalContactSpeaking: boolean = false;
  public speaking: string = null;
  public cameras: MediaDeviceInfo[] = [];
  public microphones: MediaDeviceInfo[] = [];
  public isChooseCameraMenuVisible: boolean = false;
  public isChooseMicrophoneMenuVisible: boolean = false;
  public isBroadcastPublished: boolean = false;
  public isLocalStreamPublished: boolean = false;
  public isCameraAccessDenied: boolean = false;
  public isMicrophoneAccessDenied: boolean = false;
  public trtcClient: Client = null;
  public accessTimeIntervalId: number = null;
  public accessTimeStart: Moment = null;
  public speakingCheckInterval: number = null;
  public pIsLocalAudioMuted: boolean = false;
  public pIsLocalVideoMuted: boolean = false;
  public cameraId: string = null;
  public microphoneId: string = null;
  public isScreenSharingSupported: boolean = false;
  public isScreenSharingActive: boolean = false;
  public isScreenSharingStatusChanging: boolean = false;
  public isLocalPlayerFullScreen: boolean = false;

  private pLocalContact: TContact = null;

  public get meetingRoom(): TMeetingRoomState {
    return this.getMeetingRoomById(this.meetingRoomId);
  }

  public get id(): number {
    return this.meetingRoom && this.meetingRoom.id;
  }

  public get eventId(): number {
    return this._config.eventId;
  }

  public get localContactId(): number {
    return this._config.contactId;
  }

  public get localContact(): TContact {
    return this.pLocalContact || { id: 0 };
  };

  public get isMinimized(): boolean {
    return this.meetingRoom && this.meetingRoom.isMinimized;
  }

  public get isMaximized(): boolean {
    return this.meetingRoom && this.meetingRoom.isMaximized;
  }

  public get dialogTitle(): TranslateResult {
    return this.$t('meetingRooms["Live broadcast"]');
  }

  public get isLocalAudioMuted(): boolean {
    return !this.microphoneId || this.pIsLocalAudioMuted || this.isMicrophoneAccessDenied;
  }

  public get isLocalVideoMuted(): boolean {
    return !this.cameraId || this.pIsLocalVideoMuted || this.isCameraAccessDenied;
  }

  public get toggleMuteLabel(): TranslateResult {
    return this.isLocalAudioMuted ? this.$t('meetingRooms.unmute') : this.$t('meetingRooms.mute');
  }

  public get toggleVideoLabel(): TranslateResult {
    return this.isLocalVideoMuted ? this.$t('meetingRooms["resume video"]') : this.$t('meetingRooms["stop video"]');
  }

  public get toggleMuteTitle(): TranslateResult {
    return (!this.microphoneId || this.isMicrophoneAccessDenied) ? this.$t('meetingRooms.noAvailableMicrophones') : this.toggleMuteLabel;
  }

  public get toggleVideoTitle(): TranslateResult {
    return (!this.cameraId || this.isCameraAccessDenied) ? this.$t('meetingRooms.noAvailableCameras') : this.toggleVideoLabel;
  }

  public get isCameraAndMicrophoneAccessDenied(): boolean {
    return this.isCameraAccessDenied && this.isMicrophoneAccessDenied;
  }

  public get toggleScreenShareTitle(): TranslateResult {
    return this.isScreenSharingActive ? this.$t('meetingRooms.stopScreenShare') : this.$t('meetingRooms.startScreenShare');
  }

  public get toggleScreenShareLabel(): TranslateResult {
    return this.isScreenSharingActive ? this.$t('meetingRooms.stopScreenShare') : this.$t('meetingRooms.startScreenShare');
  }

  public get microphonesMenuItems(): TItemsMenuItem[] {
    if (!this.microphones) {
      return [];
    }
    const items: TItemsMenuItem[] = [];
    this.microphones.forEach((device: any): void => {
      items.push({
        value: device.deviceId,
        label: device.label,
      });
    });
    return items;
  }

  public get camerasMenuItems(): TItemsMenuItem[] {
    if (!this.cameras) {
      return [];
    }
    const items: TItemsMenuItem[] = [];
    this.cameras.forEach((device: any): void => {
      items.push({
        value: device.deviceId,
        label: device.label,
      });
    });
    return items;
  }

  public created(): void {
    this.cameraId = localStorage.getItem('cameraId') || null;
    this.microphoneId = localStorage.getItem('microphoneId') || null;
    this.isScreenSharingSupported = TRTC.isScreenShareSupported() || false;
  }

  public async mounted(): Promise<void> {
    this.loadLocalContact();

    this.isSystemRequirementsOK = await TRTC.checkSystemRequirements();
    if (!this.isSystemRequirementsOK) {
      return;
    }

    this._subscribeToGlobalEvents();

    await this._checkForVideoAudioAccess();
    if (this.isCameraAndMicrophoneAccessDenied) {
      return;
    }

    this.trtcClient = TRTC.createClient(TRTCHelper.createClientConfig({
      type: this.type,
      userId: this._localTrtcUserId,
      mode: this.type === MeetingRoomType.BROADCAST ? TRTCMode.LIVE : TRTCMode.RTC,
      broadcastType: this._config.broadcastType,
    }));
    this._subscribeTrtcClientEvents();

    this._join();
    this._startSpeakingCheck();
  }

  public beforeDestroy(): void {
    this._stopScreenSharing();
    this._unsubscribeFromGlobalEvents();
    this._stopSpeakingCheck();
    this._stopCountingAccessTime();
    this._disconnectFromTRTC();
    this.dragZoneMouseDown$.complete();
  }

  public onTopCenterMouseDown(event: MouseEvent): void {
    if (!this.isMinimized) {
      return;
    }
    this.dragZoneMouseDown$.next(event);
  }

  public contactName(contact: TContact): string {
    return ContactHelper.getFullName(contact);
  }

  public async toggleFullScreen(): Promise<void> {
    if (!this.$refs.localPlayer) {
      return;
    }

    const localPlayer = this.$refs.localPlayer as HTMLDivElement;
    if (this.isLocalPlayerFullScreen) {
      const exitFullScreen =
        document.exitFullscreen
        // @ts-ignore
        || document.webkitExitFullscreen
        // @ts-ignore
        || document.mozCancelFullScreen
        // @ts-ignore
        || document.msExitFullscreen;
      if (exitFullScreen) {
        await exitFullScreen.call(document);
        this.isLocalPlayerFullScreen = false;
      }
    } else {
      const requestFullScreen =
        localPlayer.requestFullscreen
        // @ts-ignore
        || localPlayer.webkitRequestFullscreen
        // @ts-ignore
        || localPlayer.mozRequestFullScreen
        // @ts-ignore
        || localPlayer.msRequestFullscreen;
      if (requestFullScreen) {
        await requestFullScreen.call(localPlayer);
        this.isLocalPlayerFullScreen = true;
      }
    }
  }

  public toggleScreenShare(): void {
    if (!this.isScreenSharingSupported) {
      return;
    }
    if (!this.isScreenSharingActive) {
      this._startScreenSharing();
    } else {
      this._stopScreenSharing();
    }
  }

  public toggleMute(): void {
    if (!this.localStream || !this.microphoneId) {
      return;
    }
    if (this.pIsLocalAudioMuted) {
      this._unmuteLocalAudio();
    } else {
      this._muteLocalAudio();
    }
  }

  public toggleVideo(): void {
    if (!this.localStream || !this.cameraId) {
      return;
    }
    if (this.pIsLocalVideoMuted) {
      this._unmuteLocalVideo();
    } else {
      this._muteLocalVideo();
    }
  }

  public toggleMinimize(): void {
    if (this.isMinimized) {
      this.$store.dispatch('meetingRoomsStore/unMinimize', this.id);
    } else {
      this.$store.dispatch('meetingRoomsStore/minimize', this.id);
    }
  }

  public toggleMaximize(): void {
    if (this.isMaximized) {
      this.$store.dispatch('meetingRoomsStore/unMaximize', this.id);
    } else {
      this.$store.dispatch('meetingRoomsStore/maximize', this.id);
    }
  }

  public leave(): void {
    this.$store.dispatch('meetingRoomsStore/leave', this.id);
  }

  public setIsConnecting(isConnecting: boolean): void {
    this.isConnecting = isConnecting;
    if (!this.isConnecting && this.type === MeetingRoomType.MEETING) {
      this._startCountingAccessTime();
    }
  }

  public onSystemCheckErrorOkClick(): void {
    this.isSystemCheckErrorMessageVisible = false;
  }

  public onChooseCameraClick(event: MouseEvent): void {
    event.stopPropagation();
    this.isChooseCameraMenuVisible = !this.isChooseCameraMenuVisible;
    this.isChooseMicrophoneMenuVisible = false;
  }

  public onChooseMicrophoneClick(event: MouseEvent): void {
    event.stopPropagation();
    this.isChooseMicrophoneMenuVisible = !this.isChooseMicrophoneMenuVisible;
    this.isChooseCameraMenuVisible = false;
  }

  public async chooseCamera(deviceId: string): Promise<void> {
    if (!this.localStream) {
      return;
    }
    await this.localStream.switchDevice('video', deviceId);
    this.cameraId = deviceId;
    if (this.pIsLocalVideoMuted) {
      this._muteLocalVideo();
    } else {
      this._unmuteLocalVideo();
    }
  }

  public async chooseMicrophone(deviceId: string): Promise<void> {
    if (!this.localStream) {
      return;
    }
    await this.localStream.switchDevice('audio', deviceId);
    this.microphoneId = deviceId;
    if (this.pIsLocalAudioMuted) {
      this._muteLocalAudio();
    } else {
      this._unmuteLocalAudio();
    }
  }

  public onBroadcastPublishClick(): void {
    this.isBroadcastPublished = true;
    this._publishLocalStream();
  }

  public onBroadcastUnpublishClick(): void {
    this.isBroadcastPublished = false;
    this._unpublishLocalStream();
  }

  private async loadLocalContact(): Promise<void> {
    const localContact = await contactsApi.getContact({
      eventId: this.eventId,
      contactId: this.localContactId,
    });
    if (localContact) {
      this.pLocalContact = localContact;
    } else {
      // what ?
      // TODO:
    }
    this.isLoadingLocalContact = false;
  }

  @Watch('cameraId')
  private _onCameraIdChange(newValue: string): void {
    if (newValue) {
      localStorage.setItem('cameraId', newValue);
    } else {
      localStorage.removeItem('cameraId');
    }
  }

  @Watch('microphoneId')
  private _onMicrophoneIdChange(newValue: string): void {
    if (newValue) {
      localStorage.setItem('microphoneId', newValue);
    } else {
      localStorage.removeItem('microphoneId');
    }
  }

  private get _config(): TMeetingRoomConfig {
    return this.meetingRoom && this.meetingRoom.config;
  }

  private get _localTrtcUserId(): string {
    return MeetingRoomsHelper.getLocalTrtcUserId(this._config);
  }

  private _subscribeTrtcClientEvents(): void {
    /* do nothing ? */
  }

  private async _join(): Promise<void> {

    let cameras: any[] = [];
    let microphones: any[] = [];
    try {
      cameras = await TRTC.getCameras();
    } catch {
      /* do nothing */
    }
    try {
      microphones = await TRTC.getMicrophones();
    } catch {
      /* do nothing */
    }

    await this.trtcClient.join({ roomId: this.id });

    let cameraId = this.cameraId;
    let microphoneId = this.microphoneId;
    this.cameras = cameras || [];
    this.microphones = microphones || [];

    if (this.cameras.length) {
      let selectedCamera;
      if (cameraId !== null) {
        selectedCamera = this.cameras.find(camera => /* !!camera.deviceId && */ camera.deviceId === cameraId);
      }
      if (!selectedCamera) {
        selectedCamera = cameras[0];
      }
      cameraId = selectedCamera ? selectedCamera.deviceId : null;
    } else {
      cameraId = null;
    }
    if (cameraId === null) {
      this.cameras = [];
    }

    if (this.microphones.length) {
      let selectedMicrophone;
      if (microphoneId !== null) {
        selectedMicrophone = microphones.find(microphone => /* !!microphone.deviceId && */ microphone.deviceId === microphoneId);
      }
      if (!selectedMicrophone) {
        selectedMicrophone = microphones[0];
      }
      microphoneId = selectedMicrophone ? selectedMicrophone.deviceId : null;
    } else {
      microphoneId = null;
    }
    if (microphoneId === null) {
      this.microphones = [];
    }

    this.localStream = null;
    let localStream: LocalStream;

    try {
      localStream = TRTC.createStream({
        audio: this.microphones.length > 0 && !this.isMicrophoneAccessDenied,
        video: this.cameras.length > 0 && !this.isCameraAccessDenied,
        cameraId: this.isCameraAccessDenied ? undefined : (cameraId || undefined),
        microphoneId: this.isMicrophoneAccessDenied ? undefined : (microphoneId || undefined),
        mirror: true,
      });
      await localStream.initialize();
    } catch {
      /* mac os says permissions are 'granted' but then denies the permission */
      /* will try to init stream without camera and then without microphone */
      try {
        localStream = TRTC.createStream({
          audio: this.microphones.length > 0 && !this.isMicrophoneAccessDenied,
          video: false,
          microphoneId: this.isMicrophoneAccessDenied ? undefined : (microphoneId || undefined),
        });
        await localStream.initialize();
        this.isCameraAccessDenied = true;
      } catch {
        this.isMicrophoneAccessDenied = true;
        try {
          localStream = TRTC.createStream({
            audio: false,
            video: this.cameras.length > 0 && !this.isCameraAccessDenied,
            cameraId: this.isCameraAccessDenied ? undefined : (cameraId || undefined),
            mirror: true,
          });
          await localStream.initialize();
        } catch {
          this.isCameraAccessDenied = true;
        }
      }
    }

    this.localStream = localStream || null;
    if (!this.localStream) {
      this.isCameraAccessDenied = true;
      this.isMicrophoneAccessDenied = true;
      return;
    }

    if (!cameraId || !microphoneId) {
      cameras = [];
      microphones = [];
      try {
        cameras = await TRTC.getCameras();
      } catch {
        /* do nothing */
      }
      try {
        microphones = await TRTC.getMicrophones();
      } catch {
        /* do nothing */
      }

      const videoTrack = this.localStream.getVideoTrack();
      const audioTrack = this.localStream.getAudioTrack();
      if (videoTrack) {
        const videoTrackSettings = videoTrack.getSettings();
        cameraId = videoTrackSettings.deviceId;
      }
      if (audioTrack) {
        const audioTrackSettings = audioTrack.getSettings();
        microphoneId = audioTrackSettings.deviceId;
      }

      this.cameras = cameras;
      this.microphones = microphones;
    }

    this.cameraId = cameraId;
    this.microphoneId = microphoneId;

    this.setIsConnecting(false);

    Vue.nextTick(() => {
      this.localStream.play('local-player', DEFAULT_PLAYER_CONFIG_LOCAL);
    });
  }

  private async _stopScreenSharing(): Promise<void> {

    if (!this.isScreenSharingActive) {
      return;
    }

    this.isScreenSharingStatusChanging = true;
    this.isScreenSharingActive = false;

    const localStream = TRTC.createStream({
      audio: !this.isMicrophoneAccessDenied,
      video: !this.isCameraAccessDenied,
      cameraId: this.cameraId || undefined,
      microphoneId: this.microphoneId || undefined,
      mirror: true,
    });
    await localStream.initialize();

    if (this.localStream) {
      if (this.isBroadcastPublished) {
        await this.trtcClient.unpublish(this.localStream);
      }
      this.localStream.close();
      this.localStream = null;
    }

    this.localStream = localStream;
    if (this.pIsLocalVideoMuted) {
      this._muteLocalVideo();
    } else {
      this._unmuteLocalVideo();
    }
    if (this.pIsLocalAudioMuted) {
      this._muteLocalAudio();
    } else {
      this._unmuteLocalAudio();
    }
    if (this.isBroadcastPublished) {
      await this.trtcClient.publish(this.localStream);
    }

    Vue.nextTick(() => {
      this.localStream.play('local-player', DEFAULT_PLAYER_CONFIG_LOCAL);
    });

    this.isScreenSharingStatusChanging = false;
  }

  private async _startScreenSharing(): Promise<void> {
    if (!this.isScreenSharingSupported || this.isScreenSharingActive) {
      return;
    }

    this.isScreenSharingStatusChanging = true;
    this.isScreenSharingActive = true;

    let localStream;
    try {
      localStream = TRTC.createStream({
        audio: !this.isMicrophoneAccessDenied && !!this.microphoneId,
        video: false,
        screen: true,
        microphoneId: this.microphoneId || undefined,
      });
      await localStream.initialize();
    } catch {
      this.isScreenSharingStatusChanging = false;
      this.isScreenSharingActive = false;
      return;
    }

    localStream.on('screen-sharing-stopped', (): void => {
      this._stopScreenSharing();
    });

    if (this.localStream) {
      if (this.isBroadcastPublished) {
        await this.trtcClient.unpublish(this.localStream);
      }
      this.localStream.close();
      this.localStream = null;
    }

    this.localStream = localStream;
    if (this.pIsLocalVideoMuted) {
      this._muteLocalVideo();
    } else {
      this._unmuteLocalVideo();
    }
    if (this.pIsLocalAudioMuted) {
      this._muteLocalAudio();
    } else {
      this._unmuteLocalAudio();
    }
    if (this.isBroadcastPublished) {
      await this.trtcClient.publish(this.localStream);
    }

    Vue.nextTick(() => {
      this.localStream.play('local-player', DEFAULT_PLAYER_CONFIG_LOCAL);
    });

    this.isScreenSharingStatusChanging = false;
  }

  private _startCountingAccessTime(): void {
    if (this.accessTimeIntervalId) {
      clearInterval(this.accessTimeIntervalId);
    }
    this.accessTime = '00:00:00';
    this.accessTimeStart = this.$moment();
    this.accessTimeIntervalId = window.setInterval(() => {
      const currentTime = this.$moment();
      const diff = currentTime.diff(this.accessTimeStart);
      this.accessTime = this.$moment(diff).utc(false).format('HH:mm:ss');
    }, 1000);
  }

  private _stopCountingAccessTime(): void {
    if (!this.accessTimeIntervalId) {
      return;
    }
    clearInterval(this.accessTimeIntervalId);
    this.accessTimeIntervalId = null;
  }

  private _startSpeakingCheck(): void {
    this._stopSpeakingCheck();
    this.speakingCheckInterval = window.setInterval(() => {
      const localAudioLevel = this.localStream ? this.localStream.getAudioLevel() : 0.0;
      this.isLocalContactSpeaking = localAudioLevel > SPEAKING_AUDIO_LEVEL_THRESHOLD;
      this.speaking = null;
      if (this.isLocalContactSpeaking) {
        this.speaking = (this.localContact.name || '') + ' ' + (this.localContact.surname || '');
      }
    }, SPEAKING_CHECK_INTERVAL);
  }

  private _stopSpeakingCheck(): void {
    if (this.speakingCheckInterval) {
      clearInterval(this.speakingCheckInterval);
      this.speakingCheckInterval = null;
    }
  }

  private _subscribeToGlobalEvents(): void {
    document.addEventListener('click', this._onDocumentClick);
    window.addEventListener('beforeunload', this._onWindowBeforeUnload);
  }

  private _unsubscribeFromGlobalEvents(): void {
    document.removeEventListener('click', this._onDocumentClick);
    window.removeEventListener('beforeunload', this._onWindowBeforeUnload);
  }

  private _onDocumentClick(): void {
    this.isChooseCameraMenuVisible = false;
    this.isChooseMicrophoneMenuVisible = false;
  }

  private _muteLocalAudio(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.muteAudio();
    this.pIsLocalAudioMuted = true;
  }

  private _unmuteLocalAudio(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.unmuteAudio();
    this.pIsLocalAudioMuted = false;
  }

  private _muteLocalVideo(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.muteVideo();
    this.pIsLocalVideoMuted = true;
  }

  private _unmuteLocalVideo(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.unmuteVideo();
    this.pIsLocalVideoMuted = false;
  }

  private async _disconnectFromTRTC(): Promise<void> {
    this._muteLocalAudio();
    this._muteLocalVideo();

    const disconnect = async (): Promise<void> => {
      await this._trtcClientDisconnect();
      this._stopLocalStream();
    };

    if (this.isBroadcastPublished) {
      /* wait some time before disconnect */
      setTimeout(disconnect, BROADCAST_DISCONNECT_TIMEOUT);
    } else {
      await disconnect();
    }
  }

  private async _trtcClientDisconnect(): Promise<void> {
    if (!this.trtcClient) {
      return;
    }
    try {
      await this.trtcClient.unpublish(this.localStream);
    } catch (error) { /* do nothing */ }
    try {
      await this.trtcClient.leave();
    } catch (error) { /* do nothing */ }
    this.trtcClient.off('*');
  }

  private _stopLocalStream(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.stop();
    this.localStream.close();
    this.localStream = null;
  }

  private async _publishLocalStream(): Promise<void> {
    if (this.isLocalStreamPublished) {
      return;
    }
    await this.trtcClient.publish(this.localStream);
    this.isLocalStreamPublished = true;
    this._startCountingAccessTime();
  }

  private async _unpublishLocalStream(): Promise<void> {
    if (!this.isLocalStreamPublished) {
      return;
    }
    await this.trtcClient.unpublish(this.localStream);
    this.isLocalStreamPublished = false;
    this._stopCountingAccessTime();
  }

  private _onWindowBeforeUnload(event: Event): string {
    if (this.isCameraAndMicrophoneAccessDenied) {
      return undefined;
    }
    event.returnValue = false; // 'Sure?';
    return 'Sure?';
  }

  private async _checkForVideoAudioAccess(): Promise<void> {
    try {
      const cameraResult = await navigator.permissions.query({ name: 'camera' });
      if (cameraResult.state === 'denied') {
        this.isCameraAccessDenied = true;
      }
      const microphoneResult = await navigator.permissions.query({ name: 'microphone' });
      if (microphoneResult.state === 'denied') {
        this.isMicrophoneAccessDenied = true;
      }
    } catch (error) {
      /* do nothing */
    }
  }
}
