


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import IconEventChat from '@/_modules/icons/components/icon-event-chat.vue';

@Component({
  name: 'event-chat-button',
  components: {
    IconEventChat,
  },
})
export default class EventChatButton extends Vue {

  public onClick(event: MouseEvent): void {
    this.$emit('click', event);
  }

}
