


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { TChatContact } from '@/_modules/chat/types/chat-contact.type';
import Avatar from '@/_components/avatar/avatar.vue';

@Component({
  name: 'event-chat-component',
  components: { Avatar },
  props: {
    contact: {
      type: Object as () => TChatContact,
    }
  },
})
export default class EventChatContact extends Vue {

  public contact: TChatContact;

  public get companyInfo(): string {
    let info = (this.contact.position || '').trim();
    if (this.contact.company) {
      info += (info ? ', ' : '') + (this.contact.company || '');
    }
    return info;
  }

}

