


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { ChatConnectStatus } from '@/_modules/chat/types/chat-connect-status.enum';
import EventChatHeader from '@/_modules/event-chat/components/event-chat-header/event-chat-header.vue';
import ChatMessageComposer
  from '@/_modules/chat/components/chat-message-composer/chat-message-composer.vue';
import EventChatContactsList
  from '@/_modules/event-chat/components/event-chat-contacts-list/event-chat-contacts-list.vue';
import chatService from '@/_modules/chat/services/chat.service';
import { TEvent } from '@/_types/event.type';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import ChatMessages from '@/_modules/chat/components/chat-messages/chat-messages.vue';

@Component({
  name: 'event-chat-dialog',
  components: {
    EventChatHeader,
    ChatMessageComposer,
    EventChatContactsList,
    ChatMessages,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      connectStatus: 'chatStore/connectStatus',
      lastError: 'chatStore/lastError',
      isKickedOut: 'chatStore/isKickedOut',
      isContactsListVisible: 'eventChatStore/isContactsListVisible',
      isLoading: 'eventChatStore/isLoading',
      messages: 'eventChatStore/messages',
    }),
  }
})
export default class EventChatDialog extends Vue {

  public readonly ChatConnectStatus: typeof ChatConnectStatus = ChatConnectStatus;

  public readonly event: TEvent;
  public readonly connectStatus: ChatConnectStatus;
  public readonly lastError: Error;
  public readonly isKickedOut: boolean;
  public readonly isContactsListVisible: boolean;
  public readonly isLoading: boolean;

  public isConnectError: boolean = false;

  public async mounted(): Promise<void> {
    this.isConnectError = false;
    await chatService.connect();
    if (this.lastError) {
      this.isConnectError = true;
      return;
    }
    await chatService.joinEventChatGroup();
  }

  public get isConnecting(): boolean {
    return this.connectStatus === ChatConnectStatus.CONNECTING || this.isLoading;
  }

  public get chatCroupId(): string {
    return ChatHelper.getEventGroupId(this.event);
  }

}
