


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TEvent } from '@/_types/event.type';
import IconCloseCircleOutline from '@/_modules/icons/components/icon-close-circle-outline.vue';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import { ChatConnectStatus } from '@/_modules/chat/types/chat-connect-status.enum';

@Component({
  name: 'event-chat-header',
  components: {
    IconCloseCircleOutline,
    IconArrowLeft,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      connectStatus: 'chatStore/connectStatus',
      countContacts: 'eventChatStore/countContacts',
      isContactsListVisible: 'eventChatStore/isContactsListVisible',
      contactsListError: 'eventChatStore/contactsListError',
      isContactsListLoading: 'eventChatStore/isContactsListLoading',
    }),
  }
})
export default class EventChatHeader extends Vue {

  public ChatConnectStatus: typeof ChatConnectStatus = ChatConnectStatus;
  public event: TEvent;
  public countContacts: number;
  public isContactsListVisible: boolean;
  public connectStatus: ChatConnectStatus;
  public contactsListError: Error;
  public isContactsListLoading: boolean;

  public get isCountContactsVisible(): boolean {
    return this.connectStatus === ChatConnectStatus.CONNECTED
      && !this.isContactsListVisible
      && !this.isContactsListLoading
      && !this.contactsListError;
  }

  public onButtonCloseClick(): void {
    this.$store.dispatch('eventChatStore/setIsDialogVisible', false);
  }

  public onCountContactsClick(): void {
    this.$store.dispatch('eventChatStore/setIsContactsListVisible', true);
  }

  public onButtonArrowLeftClick(): void {
    this.$store.dispatch('eventChatStore/setIsContactsListVisible', false);
  }

}
