


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import EventChatButton from '@/_modules/event-chat/components/event-chat-button/event-chat-button.vue';
import EventChatDialog from '@/_modules/event-chat/components/event-chat-dialog/event-chat-dialog.vue';

@Component({
  name: 'event-chat',
  components: {
    EventChatButton,
    EventChatDialog,
  },
  computed: {
    ...mapGetters({
      isConfigured: 'chatStore/isConfigured',
      isDialogVisible: 'eventChatStore/isDialogVisible',
      isSideBarRightOpen: 'sideBarRightStore/isOpen',
    }),
  }
})
export default class EventChat extends Vue {

  public readonly isConfigured: boolean;
  public readonly isDialogVisible: boolean;
  public readonly isSideBarRightOpen: boolean;

  public onEventChatButtonClick(): void {
    this.$store.dispatch('eventChatStore/toggleIsDialogVisible');
  }

}
