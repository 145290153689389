


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TChatContact } from '@/_modules/chat/types/chat-contact.type';
import EventChatContact from '@/_modules/event-chat/components/event-chat-contact/event-chat-contact.vue';

@Component({
  name: 'event-chat-contacts-list',
  components: { EventChatContact },
  computed: {
    ...mapGetters('eventChatStore', {
      contactsList: 'contactsList',
      contactsListError: 'contactsListError',
      isContactsListLoading: 'isContactsListLoading',
    }),
  }
})
export default class EventChatContactsList extends Vue {

  public contactsList: TChatContact[];
  public contactsListError: Error;
  public isContactsListLoading: boolean;

}
